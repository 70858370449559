<template>
  <v-list-item class="px-0">
    <v-list-item-avatar v-if="icon">
      <v-icon v-text="icon" />
    </v-list-item-avatar>
    <v-list-item-title v-text="title" />
    <v-spacer />
    <div v-if="value" class="text-body-1 flex-shrink-0">{{ value }}</div>
  </v-list-item>
</template>

<script>
export default {
  name: 'FormSwitcher',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
